import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Card, Grid, Button, makeStyles } from "@material-ui/core";
import Process from "./process/process";
import Quality from "./quality";
import instance from "../../../../utils/axiosHelper";
import Communication from "./communication";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import { GlobalData } from "../../../../context/globalData";
import HealthStatus from "../../../../components/helthStatus";
import { datePayload } from "../../../../utils/moduleTabApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { config } from "../../../../Config/permission";
import processHealthImg from "../../../../assets/processHealth.png";
import communicationHealthImg from "../../../../assets/communicationHealth.png";
import qualityHealthImg from "../../../../assets/qualityHealth.png";
import executionHealthImg from "../../../../assets/executionHealth.png";
import StatusTable from "./execution/StatusTable";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  borderBottom: {
    borderBottom: "1px solid gray",
    paddingBottom: 4,
  },
  active: {
    borderBottom: " 5px solid #5EBDCC !important",
    paddingBottom: 0,
  },
});

function Index() {
  const [active, setActive] = useState("Delivery Tracker");
  const classes = useStyles();
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);
  const headerMenu = config.project
    .projectTabHealthToProcessCommunicationQuality
    ? ["Delivery Tracker", "Process", "Quality Assurance", "Collaboration"]
    : ["Delivery Tracker"];

  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [showTrend, setShowTrend] = useState(false);
  const [executionStatus, setExecutionStatus] = useState();
  const [processStatus, setProcessStatus] = useState();
  const [communicationStatus, setCommunicationStatus] = useState();
  const [qualityStatus, setQualityStatus] = useState();

  const fetchData = async (type, url) => {
    try {
      const res = await instance.get(url);
      if (type === "Process") {
        setProcessStatus({
          status: res?.data[0]?.HealthStatus,
          recommendation: res?.data[0]?.additionalText,
          score: res?.data[0]?.HealthScore,
        });
      } else if (type === "Quality Assurance") {
        setQualityStatus({
          status: res?.data[0]?.healthStatus,
          recommendation: res?.data[0]?.additionalText,
          score: res?.data[0]?.healthScore,
        });
      } else if (type === "Communication") {
        setCommunicationStatus({
          status: res?.data[0]?.HealthStatus,
          recommendation: res?.data[0]?.additionalText,
          score: res?.data[0]?.HealthScore,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProcessStatus = (type, date = projectRangePickerValue) => {
    const dateUrl = datePayload(date);
    if (type === "Process") {
      const url = `/api/v2/ph/health/project/${projectId}?${dateUrl}`;
      fetchData(type, url);
    } else if (type === "Quality Assurance") {
      const url = `/api/v2/quality/get/resource/healthstatus/${projectId}?${dateUrl}`;
      fetchData(type, url);
    } else if (type === "Communication") {
      const url = `/api/v2/cm/health/project/${projectId}?${dateUrl}`;
      fetchData(type, url);
    }
  };

  const getComponent = useMemo(() => {
    switch (active) {
      case "Process":
        return (
          <Card className="p-3">
            <Process
              getProcessStatus={getProcessStatus}
              setShowTrend={setShowTrend}
              showTrend={showTrend}
              date={date}
              recommendation={processStatus}
            />
          </Card>
        );
      case "Collaboration":
        return (
          <Card className="p-3">
            <Communication
              getProcessStatus={getProcessStatus}
              showTrend={showTrend}
              setShowTrend={setShowTrend}
              date={date}
              recommendation={communicationStatus}
            />
          </Card>
        );
      case "Quality Assurance":
        return (
          <Card className="p-3">
            <Quality
              getProcessStatus={getProcessStatus}
              date={date}
              setShowTrend={setShowTrend}
              showTrend={showTrend}
              recommendation={qualityStatus}
            />
          </Card>
        );
      case "Delivery Tracker":
        // return <ScenarioPlanner setExecutionStatus={setExecutionStatus} />;
        return <StatusTable />;
      default:
        return "";
    }
  }, [active, date, showTrend]); // Dependencies array

  useEffect(() => {
    getProcessStatus("Process");
    getProcessStatus("Quality Assurance");
    getProcessStatus("Communication");
  }, []);

  return (
    <>
      <Grid container spacing={3} className="mb-2">
        {headerMenu.map((e) => (
          <Grid item xs={3} key={e}>
            <Card
              className={`${classes.borderBottom} cursorPointer border ${
                active === e ? classes.active : ""
              }`}
              onClick={() => setActive(e)}
            >
              <Box px={3} textAlign={"center"} py={1}>
                {e === "Collaboration" && (
                  <>
                    <Box mb={2}>
                      <img
                        src={communicationHealthImg}
                        alt=""
                        height={"100px"}
                        className="rounded-circle"
                      />
                    </Box>
                    <HealthStatus
                      status={communicationStatus?.status}
                    ></HealthStatus>
                    <span>({communicationStatus?.score}%)&nbsp;</span>
                  </>
                )}
                {e === "Process" && (
                  <>
                    <Box mb={2}>
                      <img src={processHealthImg} alt="" Height={"100px"} />
                    </Box>
                    <HealthStatus status={processStatus?.status}></HealthStatus>
                    <span>({processStatus?.score}%)</span>{" "}
                  </>
                )}
                {e === "Quality Assurance" && (
                  <>
                    <Box mb={2}>
                      <img
                        src={qualityHealthImg}
                        alt=""
                        height={"100px"}
                        className="rounded-circle"
                      />
                    </Box>
                    <HealthStatus status={qualityStatus?.status}></HealthStatus>
                    <span>({qualityStatus?.score}%)&nbsp;</span>
                  </>
                )}
                {e === "Delivery Tracker" && (
                  <>
                    {/* {executionStatus && ( */}
                    <>
                      <Box mb={2}>
                        <img src={executionHealthImg} alt="" Height={"100px"} />
                      </Box>
                      <HealthStatus status={executionStatus}></HealthStatus>
                    </>
                    {/* )} */}
                  </>
                )}
                {e}
                <span
                  onClick={() => {
                    setIsDefinaionPopup(true);
                    setDefinationKPI(e);
                  }}
                >
                  <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                </span>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Card className="p-3"> */}
      {active !== "Delivery Tracker" && (
        <Box mb={3} display={"flex"} gridGap={10}>
          <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          <Button
            onClick={() => setShowTrend(true)}
            variant="contained"
            color="primary"
          >
            {" "}
            Show Trend
          </Button>
        </Box>
      )}
      {getComponent}
    </>
  );
}

export default Index;
