import React, { useContext, useEffect } from "react";
import Logo from "../../assets/itechLogo.png";
import Devboost from "../../assets/Devboost-logo.png";
import DevboostSvg from "../../assets/Devboost-logo.svg";
import { makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { GlobalData } from "../../context/globalData";
import moduleIcon from "../../assets/module_icon.svg";
import teamIcon from "../../assets/team_logo.svg";
import resourceIcon from "../../assets/resource_icon.svg";
import sprintIcon from "../../assets/sprint_icon.svg";
import resourceAllocationIcon from "../../assets/resourceAllocation.png";
import riskIcon from "../../assets/risk_icon.svg";
import notificationIcon from "../../assets/notification_icon.svg";
import setupIcon from "../../assets/setup_icon.svg";
import settingIcon from "../../assets/setting_icons.svg";
import logoutIcon from "../../assets/logout_icon.svg";
import riskImage from "../../assets/Risk.png";
import { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../utils/axiosHelper";
import { PROJEECT_API_URL_GEN } from "../../utils/projectTabApi";
import { iterationMap } from "../../utils/textMap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { config } from "../../Config/permission";

const useStyles = makeStyles((theme) => ({
  sideBarWrapper: {
    background: "#FFFFFF",
    boxShadow: "0px 16px 20px #0000000F",
    height: "100vh",
    width: "236px",
    padding: "25px 20px",
    position: "fixed",
    top: "0px",
    left: "0px",
    overflow: "auto",
  },
  listContainer: {
    marginTop: "12px",
    padding: 0,
    listStyle: "none",
    "& a, span": {
      fontSize: 15,

      padding: "17px 15px",
      borderRadius: 4,
      textDecoration: "none",
      width: "100%",
      display: "block",
      color: "#707070",
      cursor: "pointer",
      "& i": {
        marginRight: "10px",
        color: "#707070",
      },
      "& img": {
        marginRight: "10px",
      },
    },
    "& a.active": {
      background: "#5EBDCC",
      color: "#FFFFFF",
      "& i": {
        color: "#FFFFFF",
      },
    },
  },
  innerMenu: {
    padding: "0 0 0 10",
    listStyle: "none",
    "& a, span": {
      fontSize: 13,
      padding: "10px 4px",
    },
    "& span.active": {
      fontSize: 13,
      padding: 10,
      background: "#5EBDCC",
      color: "#FFFFFF",
      "& i": {
        color: "#FFFFFF",
      },
    },
  },
}));

function Sidebar() {
  const [allTeamName, setAllTeamName] = useState();
  const [SelectedTeam, setSelectedTeam] = useState();
  const history = useHistory();
  const { setIsLogin, uniqueuserlistByEmail, setUniqueuserlistByEmail } =
    useContext(GlobalData);
  const style = useStyles();
  // const { setIsLogin } = useContext(GlobalData);

  // ? do not delete the commented code (remember feature)
  const logoutHandler = () => {
    // let userList = localStorage.getItem("userList");
    localStorage.clear();
    // localStorage.setItem("userList", userList);
    setIsLogin(false);
    // setUniqueuserlistByEmail(userList);
  };
  const {
    iterationValueGlobal,
    projectRangePickerValue: rangePickerValue,
    savePageData,
    selectedprojectId,
  } = useContext(GlobalData);

  useEffect(() => {
    axios
      .get(
        `/api/v2/projects/details?no_of_days=90&startDay=2024-01-20&endDay=2024-04-18&iteration=7`
      )
      .then((response) => {
        setAllTeamName(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  return (
    <aside className={style.sideBarWrapper}>
      <img
        src={DevboostSvg}
        // src={Devboost}
        // style={{ width: "164px", height: "33px" }}
        width={"100%"}
        alt="itechgenic"
        className="mt-1"
      />
      <ul className={style.listContainer}>
        {/* <li>
          <NavLink
            to="/project"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <i className="fa fa-folder-o" width={16} height={16} />
            Project
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to="/modules"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={moduleIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Modules
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to="/resourceAllocation"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={resourceAllocationIcon}
              alt=""
              width={20}
              height={20}
              style={{ fill: "white" }}
            ></img>
            Resource Allocation
          </NavLink>
        </li> */}
        {config?.sprint?.sideBarSprint && (
          <li>
            <NavLink
              to="/sprint"
              className={({ isActive }) => (isActive ? style.active : "")}
            >
              <img
                src={sprintIcon}
                alt=""
                width={16}
                height={16}
                style={{ fill: "white" }}
              ></img>
              Sprint
            </NavLink>
          </li>
        )}
        {/* <li>
          <NavLink
            to="/investment-profile"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={resourceIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Investment Profile
          </NavLink>
        </li> */}
        {/* <ExpandMenu title={"Select Project"} style={style}>
          {allTeamName?.map((e, index) => (
            <li key={index}>
              <NavLink
                to={`/`}
                className={({ isActive }) => (isActive ? style.active : "")}
                onClick={() => setSelectedTeam(e)}
              >
                {e.Name}
              </NavLink>
            </li>
          ))}
        </ExpandMenu> */}
        {/* <ExpandMenu title={"Project"} style={style}>
          <>
            <li>
              <NavLink
                to={{
                  pathname: SelectedTeam?.Id
                    ? `/project/${SelectedTeam?.Id}?projectName=${SelectedTeam?.Name}`
                    : "/",
                  state: { tabNumber: 0 },
                }}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                Project Metrics
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/project/${SelectedTeam?.Id}?projectName=${SelectedTeam?.Name}`}
              >
                Business impact
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{
                  pathname: SelectedTeam?.Id
                    ? `/project/${SelectedTeam?.Id}?projectName=${SelectedTeam?.Name}`
                    : "/",
                  state: { tabNumber: 3 },
                }}
              >
                Health
              </NavLink>
            </li>
          </>
        </ExpandMenu> */}
        {/* <li>
          <NavLink
            to="/process"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Process
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/projectExecution"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Project Execution
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/quality"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Quality
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/productivity"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Productivity
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/teamMorale"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Team Morale
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/interteamCommunication"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Interteam Communication
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/space"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={sprintIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Space
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="/settings/Metrics"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={settingIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            Settings
          </NavLink>
        </li>
        <li
          onClick={() => {
            logoutHandler();
          }}
        >
          <NavLink
            to="/login"
            className={({ isActive }) => (isActive ? style.active : "")}
          >
            <img
              src={logoutIcon}
              alt=""
              width={16}
              height={16}
              style={{ fill: "white" }}
            ></img>
            logout
          </NavLink>
        </li>
        <li
          onClick={() =>
            history.push({
              pathname: savePageData?.pageRout,
              data: savePageData?.tab,
            })
          }
        >
          {savePageData?.name}
        </li>
      </ul>
    </aside>
  );
}

const ExpandMenu = ({ title, children, style }) => {
  // const style = useStyles();
  const [selectedMenu, setSelectedMenu] = useState(false);
  return (
    <li>
      <span
        onClick={() => setSelectedMenu(!selectedMenu)}
        className={selectedMenu === true ? "active" : ""}
      >
        {title}
        {selectedMenu ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
      </span>
      <ul
        className={style.innerMenu}
        style={{
          display: selectedMenu ? "block" : "none",
        }}
      >
        {children}
      </ul>
    </li>
  );
};

export default Sidebar;
