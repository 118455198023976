import React, { useContext, useMemo, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import WIPimg from "../../../assets/WIP.jpeg";
import flowEfficiencyImg from "../../../assets/FlowEfficiency.jpeg";
import msg from "../../../assets/msg.png";
import check from "../../../assets/check.png";
import pr from "../../../assets/pr.png";
import commit from "../../../assets/commit.png";
import GraphCard from "./graphCard";
import { useEffect } from "react";
import CycleTimeBreakDown from "./graphs/cycleTimeBreakDown";
import WorkAlocationChart from "./graphs/workAlocationChart";
import axios from "../../../utils/axiosHelper";
import PlanningAccuracy from "./graphs/PlanningAccuracy";
import { style } from "./style";
import IssueLevel from "./IssueLevel";
import { addSpace } from "../../../utils/spaceSeprator";
import {
  PlanningAccuracyStatusList,
  peReivewSubStatus,
  prReivewStatusList,
} from "../../../Config/statusData";
import Trend from "./trend";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RiskWrapper, {
  RiskColorWrapper,
  RiskColorWrapperWithIcon,
} from "../../../components/overview/riskWrapper";
import { Box, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../context/globalData";
import {
  addLeadingZeroToDateRange,
  datePayload,
  getSelectedDate,
} from "../../../utils/moduleTabApi";
import { getMetricRange } from "../../../utils/getMetricRange";
import { Tooltip } from "@mui/material";
import { config } from "../../../Config/permission";
import IssueLevelTable from "./IssueLevel/IssueLevelTable";
import IssueLevelCycle from "./IssueLevel/IssueLevelCycle";
import HealthColorWrapper from "../../../components/overview/healthColorWrapper";
import WIPTrend from "./trend/WIPTrend";
import AllTeamTrendBar from "./trend/AllTeamTrend";
import EpicDropDown from "../../../components/epicDropDown";
import SelectField from "../../../components/select/selectField";
import RangeSelector from "../../../components/rangeSelector";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

const useStyles = style;

function ProjectDashboard({ projectName, projectId }) {
  const { setIsDefinaionPopup, setDefinationKPI, projectRangePickerValue } =
    useContext(GlobalData);

  const [barData, setBarData] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [cycleDataPullRequest, setCycleDataPullRequest] = useState([]);
  const [totalCycleData, setTotalCycleData] = useState();
  const [workAllocationData, setWorkAllocationDat] = useState([]);
  const [planningAccuracyData, setPlanningAccuracyData] = useState([]);
  const classes = useStyles();
  const [issueType, setIssueType] = useState();
  const [statusList, setStatusList] = useState([]);
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [workCompleted, setWorkCompleted] = useState();
  const [teamAvgAging, setTeamAvgAging] = useState();
  const [teamFlowEfficiency, setFlowTeamEfficiency] = useState();
  const [iteration, setIteration] = useState(30);
  const [commitwithoutData, setCommitWithoutData] = useState();
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [capacityAccuracy, setCapacityAccuracy] = useState();
  const [commentsAddressed, setCommentsAddressed] = useState();
  const [cradResponsePersent, setCradResponsePersent] = useState();
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [
    resourceAllocationStatusListData,
    setResourceAllocationStatusListData,
  ] = useState([]);
  const [firstTimePassPercentageForRang, setFirstTimePassPercentageForRang] =
    useState();
  const [risk, setRisk] = useState({});
  const [range, setRange] = useState([]);
  const [prMergeCount, setPrMergeCount] = useState({
    pr: "",
    commit: "",
    prDepth: "",
    PrReviewTime: "",
    firstTimePass: "",
  });

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url?.trim(), selectedEpic: list });
  };

  // get work break down list
  const excudePermission = [
    "Work Breakdown",
    "Commit Without Ticket Reference",
    "Commit with pull request",
    // "WIP Average Age",
    "Pr Review Time",
    "Average Flow Efficiency",
    "Cycle Time (Pull Request)",
  ];
  const getWorkBreakDown = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/workBreakdown/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/workBreakdown/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        const data = Object.keys(res.data.graph).map((e) => {
          return {
            title: e === "NewWork" ? "Feature and Enhancement" : addSpace(e),
            value: res.data.graph[e],
          };
        });
        setBarData({ CodeChanges: res.data.CodeChanges, data: data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //  statusNames
  useEffect(() => {
    const url = `/api/v2/statusNames/${projectId}`;
    axios
      .get(url)
      .then((response) => {
        setResourceAllocationStatusListData(
          response?.data?.StatusNames[0]?.Names
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [projectId]);

  const getCommentsAddressed = () => {
    axios
      .get(`/api/v2/projects/commentsaddressed/${projectId}${epicData.url}`)
      .then((res) => {
        setCommentsAddressed(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getCycletimeBreakdown = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/cycletimeBreakdown/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/cycletimeBreakdown/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then(
        (res) => {
          // if (res.data) {
          const response = res?.data?.avgDurations;
          const total = Object.values(response).reduce(
            (acc, value) => acc + value,
            0
          );
          setTotalCycleData({
            total: total.toFixed(),
            percentValue: res?.data?.percentUnderAvgDuration.toFixed(),
          });
          // Calculate percentages and format the data
          const formattedData = Object.keys(response).map((key) => ({
            title: key,
            value: response[key].toFixed(), // Format value to two decimal places
            percentage: ((response[key] / total) * 100).toFixed(), // Calculate and format percentage
          }));
          setCycleData(formattedData);
        }
        // }
      )
      .catch((err) => {});
  };
  const getCycleTimePullRequest = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/teams/cycleTimePR/${projectId}?${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then(
        (res) => {
          // if (res.data) {
          // const
          const response = res?.data;
          // const total = Object.values(response).reduce(
          //   (acc, value) => acc + value,
          //   0
          // );
          // setTotalCycleData({
          //   total: total.toFixed(),
          //   percentValue: res?.data?.percentUnderAvgDuration.toFixed(),
          // });
          // Calculate percentages and format the data
          const formattedData = Object.keys(response)
            .map((key) => ({
              title: key,
              value: response[key], // Format value to two decimal places
              // percentage: ((response[key] / total) * 100).toFixed(), // Calculate and format percentage
            }))
            .filter(
              (e) =>
                e?.title !== "Pickup" &&
                e?.title !== "Coding" &&
                e?.title !== "Review" &&
                e?.title !== "Deploy"
            );
          setCycleDataPullRequest(formattedData);
        }
        // }
      )
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getWorkAllocation = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/resourceAllocation/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/resourceAllocation/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { title: e, value: res.data[e].toFixed() };
            // return { title: e, value: res.data[e] };
          });
          setWorkAllocationDat(data);
          setAssigneeList(data.map((e) => e.title).filter((e) => e !== "null"));
        }
      })
      .catch((err) => {});
  };
  const getCommitWithout = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/commitwithout/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/commitwithout/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setCommitWithoutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlanningAccuracy = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/planningAccuracy/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/planningAccuracy/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          setWorkCompleted(res.data?.resData?.Done);
          setCapacityAccuracy(res.data?.result?.Done);
          const data = Object.keys(res.data?.result).map((e) => {
            return { title: e, value: res.data.result[e] };
          });
          setPlanningAccuracyData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPrMergeData = () => {
    const dateUrl = datePayload(date);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/prMerged/${projectId}${epicData.url}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/prMerged/${projectId}?iteration=${iteration}&${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        if (res?.data) {
          let data = (res?.data?.FirstTime / res?.data?.CompletedIssue) * 100;
          let firstTimePassPercentage = data?.toFixed();
          // let firstTimePassPercentage = data?.toFixed(2);
          setFirstTimePassPercentageForRang(firstTimePassPercentage);
          setCradResponsePersent(res?.data);
          setPrMergeCount({
            pr: `${res?.data?.PRWithoutReview}/${res?.data?.commitPr} (${+res
              ?.data?.PRPercent} %)`,
            commit: `${res?.data?.CommitWithPR}/${res?.data?.CommitCount} (${res?.data?.CommitWithPRper} %)`,
            prDepth: `${res?.data?.commitPr} / ${res?.data?.SumComment}  `,
            PrReviewTime: res?.data?.PrReviewTime,
            firstTimePass: `${res?.data?.FirstTime} / ${res?.data?.CompletedIssue} (${firstTimePassPercentage} %)`,
          });
          setRisk({
            pr: res?.data?.PRPercent,
            commit: res?.data?.CommitWithPRper,
            firstTimePass: firstTimePassPercentage,
            RiskPRCommentDepthRisk: res?.data?.RiskPRCommentDepth,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewMoreHandler = (name, list, subStatus = []) => {
    setIssueType(name);
    setStatusList(list);
    setSelectedSubStatus(subStatus);
  };

  useEffect(() => {
    if (projectId) {
      getCommentsAddressed();
    }
  }, [projectId, epicData.url]);
  useEffect(() => {
    if (projectId) {
      getWorkAllocation();
      getPlanningAccuracy();
      getCycletimeBreakdown();
      getCommitWithout();
      getPrMergeData();
      getWorkBreakDown();
    }
  }, [projectId, epicData.url, date, iteration]);

  useEffect(() => {
    getCycleTimePullRequest();
  }, [projectId, epicData, date]);

  // get ranges
  useEffect(() => {
    axios
      .get(`/api/risks?ProjectId=${projectId}`)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  // Avg Aging api
  const avgAging = () => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/avgAging/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/avgAging/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setTeamAvgAging(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  // flow Efficiency api
  const flowEfficiency = () => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url;
    if (epicData.url) {
      url = `/api/v2/projects/flowEfficiency/${projectId}${epicData.url}&${dateUrl}`;
    } else {
      url = `/api/v2/projects/flowEfficiency/${projectId}?${dateUrl}`;
    }
    axios
      .get(url)
      .then((res) => {
        setFlowTeamEfficiency(res?.data?.Efficiency);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    avgAging();
    flowEfficiency();
  }, [date, epicData]);

  const unreviewedPR = useMemo(
    () => getMetricRange(range, risk?.pr, "Unreviwed PR"),
    [risk?.pr, range]
  );
  const commitWithoutPR = useMemo(
    () => getMetricRange(range, risk?.commit, "Commit Without PR"),
    [risk?.commit, range]
  );
  const PRReviewTimeRange = useMemo(
    () =>
      getMetricRange(
        range,
        prMergeCount?.PrReviewTime,
        "PR Review Time (Days)"
      ),
    [prMergeCount?.PrReviewTime, range]
  );

  const FirstTimePassIssue = useMemo(
    () =>
      getMetricRange(
        range,
        firstTimePassPercentageForRang,
        "First Time Pass Issue"
      ),
    [firstTimePassPercentageForRang, range]
  );

  const WorkInProgressAging = useMemo(
    () =>
      getMetricRange(range, teamAvgAging?.Time, "Work In Progess Aging (Days)"),
    [teamAvgAging?.Time, range]
  );

  const AverageFlowEfficiencyRisk = useMemo(
    () => getMetricRange(range, teamFlowEfficiency, "Average Flow Efficiency"),
    [teamFlowEfficiency, range]
  );

  const commitWithoutTicketRef = useMemo(() => {
    if (!commitwithoutData?.CommitWithoutTicketReference) return;
    const val =
      commitwithoutData?.CommitWithoutTicketReference?.match(/\d+\.\d+/);
    const number = val?.length > 0 ? +val[0] : 0;
    return getMetricRange(
      range,
      number?.toFixed(2),
      "Commit Without Ticket Ref"
    );
  }, [commitwithoutData?.CommitWithoutTicketReference, range]);

  const handleAutocompleteChange = (event, value) => {
    setSelectedMetrics(value);
  };

  return (
    <>
      <Box display={"flex"}>
        <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
        <>
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div style={{ marginTop: -8 }}>
            <SelectField
              input={
                <Button
                  onClick={() => setOpenRange(true)}
                  style={{
                    border: "1px solid #b7b7b7",
                    width: "230px",
                    textTransform: "capitalize",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "14px ",
                    paddingRight: "11px",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    height: "54px",
                  }}
                  endIcon={<DateRangeOutlinedIcon />}
                >
                  {getSelectedDate(date)}
                </Button>
              }
              onClick={() => setOpenRange(true)}
            />
            <RangeSelector
              open={openRange}
              setOpen={setOpenRange}
              pickerHandler={(val) => setDate(val)}
              value={date}
              isNextDate={false}
            />
          </div>
        </>
      </Box>
      {/* <div className="col-3 customCard shadow">
        <SmallCardGraph />
      </div> */}
      {/* =============================================> All Project Graphs <=================================================== */}
      <Grid container spacing={2} className={classes.graphCard}>
        {config.project.workBreakdown && (
          <Grid item xs={6}>
            <GraphCard
              title={"Work Breakdown"}
              subTitle={
                <div className={classes.subTitle}>
                  <span className={classes.subTitleAmt}>
                    {barData?.CodeChanges}
                  </span>
                  <span className={classes.subTitleText}>Code changes</span>
                  <div
                    onClick={() =>
                      viewMoreHandler(
                        "Work Breakdown"
                        // PlanningAccuracyStatusList
                      )
                    }
                    className={classes.viewMore}
                  >
                    View More
                  </div>
                </div>
              }
            >
              {/* <BarChart data={barData.data} /> */}
              <WorkAlocationChart data={barData.data} />
            </GraphCard>
          </Grid>
        )}

        <Grid item xs={6}>
          <GraphCard
            title={"Cycle Time Breakdown"}
            subTitle={
              <div className={classes.subTitle}>
                <div
                  onClick={() =>
                    viewMoreHandler(
                      "Cycle Time Breakdown",
                      resourceAllocationStatusListData
                    )
                  }
                  className={classes.viewMore}
                >
                  View More
                </div>
              </div>
            }
            cardItem={totalCycleData}
          >
            <CycleTimeBreakDown data={cycleData} />
          </GraphCard>
        </Grid>
        <Grid item xs={6}>
          <GraphCard
            title={"Cycle Time (Pull Request)"}
            subTitle={
              <div className={classes.subTitle}>
                <div
                  onClick={() =>
                    viewMoreHandler(
                      "Cycle Time (Pull Request)",
                      resourceAllocationStatusListData
                    )
                  }
                  className={classes.viewMore}
                >
                  View More
                </div>
              </div>
            }
          >
            <div style={{ paddingTop: "60px" }}>
              <CycleTimeBreakDown
                data={cycleDataPullRequest}
                title={"Cycle Time (Pull Request)"}
              />
            </div>
          </GraphCard>
        </Grid>
        <Grid item xs={6}>
          <GraphCard
            title={"Planning Accuracy"}
            subTitle={
              <div
                onClick={() =>
                  viewMoreHandler(
                    "Planning Accuracy",
                    PlanningAccuracyStatusList
                  )
                }
                className={classes.viewMore}
              >
                View More
              </div>
            }
          >
            <PlanningAccuracy data={planningAccuracyData} />
          </GraphCard>
        </Grid>
        <Grid item xs={6}>
          <GraphCard
            title={"Work Allocation"}
            subTitle={
              <div
                onClick={() =>
                  viewMoreHandler(
                    "Work Allocation",
                    resourceAllocationStatusListData
                  )
                }
                className={classes.viewMore}
              >
                View More
              </div>
            }
          >
            <WorkAlocationChart
              data={workAllocationData}
              name="ResourceAllocation"
            />
          </GraphCard>
        </Grid>
      </Grid>
      {/* =============================================> All Project Metrics <=================================================== */}
      <Grid container spacing={2} className="my-4">
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#e8f6eb" }}
                >
                  <img src={check} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>{workCompleted}</h3>
                  <h6>Work Complete</h6>
                  <h6>({config.type} )</h6>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {config.project.commitWithoutTicketRef && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={msg} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {commitwithoutData?.CommitWithoutTicketReference}
                    </h3>
                    <h6>
                      Commit Without Ticket Reference
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Commit Without Ticket Reference");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Commit Without Ticket Reference"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {commitWithoutTicketRef?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target:{" "}
                            {commitWithoutTicketRef?.From?.$numberDecimal}% to{" "}
                            {commitWithoutTicketRef?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={commitWithoutTicketRef?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={commitWithoutTicketRef?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.unReviewedPR && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={pr} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{prMergeCount?.pr}</h3>
                    <h6>
                      Unreviewed PR
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Unreviewed PRs");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Unreviewed PR",
                            prReivewStatusList,
                            peReivewSubStatus
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {unreviewedPR?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {unreviewedPR?.From?.$numberDecimal}% to{" "}
                            {unreviewedPR?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={unreviewedPR?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={unreviewedPR?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.CommitWithPullRequest && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>{prMergeCount?.commit}</h3>
                    <h6>
                      Commit with pull request
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Commit without pull request");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Commit with pull request"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>Status: {commitWithoutPR?.RiskCategory} Risk</h6>
                          <h6>
                            Target: {commitWithoutPR?.From?.$numberDecimal}% to{" "}
                            {commitWithoutPR?.To?.$numberDecimal}%
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={commitWithoutPR?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={commitWithoutPR?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        {config.project.prReviewTime && (
          <Grid item xs={3}>
            <Card className={`${classes.card} customCard position-relative`}>
              <CardContent>
                <div className={classes.cardContaner}>
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: "#fdeeee" }}
                  >
                    <img src={commit} alt="basket" />
                  </div>
                  <div className={classes.textContianer}>
                    <h3 style={{ fontSize: "24px" }}>
                      {prMergeCount?.PrReviewTime} (Days)
                    </h3>
                    <h6>
                      Pr Review Time
                      <span
                        onClick={() => {
                          setIsDefinaionPopup(true);
                          setDefinationKPI("Pr review time");
                        }}
                      >
                        <InfoIcon
                          style={{ color: "gray", marginLeft: "8px" }}
                        />
                      </span>
                      <br />
                      <span
                        className={classes.viewMore}
                        onClick={() =>
                          viewMoreHandler(
                            "Pr Review Time"
                            // ResourceAllocationStatusList
                          )
                        }
                      >
                        View More
                      </span>
                    </h6>
                    <Tooltip
                      title={
                        <>
                          <h6>
                            Status: {PRReviewTimeRange?.RiskCategory} Risk
                          </h6>
                          <h6>
                            Target:{PRReviewTimeRange?.From?.$numberDecimal}
                            (Days) to {PRReviewTimeRange?.To?.$numberDecimal}
                            (Days)
                          </h6>
                        </>
                      }
                    >
                      <div className="metricStatus">
                        {/* <RiskColorWrapperWithIcon
                          className="metricChips"
                          riskLevel={PRReviewTimeRange?.RiskCategory}
                        ></RiskColorWrapperWithIcon> */}
                        <HealthColorWrapper
                          status={PRReviewTimeRange?.RiskCategory}
                        ></HealthColorWrapper>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={commit} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>
                    {prMergeCount?.firstTimePass}
                  </h3>
                  <h6>
                    First Time Pass Issue
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("First Time pass issue");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler(
                          "First Time pass issue",
                          resourceAllocationStatusListData
                        )
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>Status: {FirstTimePassIssue?.RiskCategory} Risk</h6>
                        <h6>
                          Target: {FirstTimePassIssue?.From?.$numberDecimal}% to{" "}
                          {FirstTimePassIssue?.To?.$numberDecimal}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={FirstTimePassIssue?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative py-2`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={WIPimg} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <>
                    <div
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <div>
                        Work In Progress - {teamAvgAging?.StoryPoints}
                        <span
                          onClick={() => {
                            setIsDefinaionPopup(true);
                            setDefinationKPI("Work In Progress");
                          }}
                        >
                          <InfoIcon
                            style={{ color: "gray", marginLeft: "8px" }}
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "18px",
                      }}
                      className="mb-1"
                    >
                      <div> Avg Age - {teamAvgAging?.Time}</div>
                    </div>
                  </>
                  <h6>
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler(
                          "WIP Average Age"
                          // resourceAllocationStatusListData
                        )
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {WorkInProgressAging?.RiskCategory} Risk
                        </h6>
                        <h6>
                          Target: {WorkInProgressAging?.From?.$numberDecimal}%
                          to {WorkInProgressAging?.To?.$numberDecimal}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={WorkInProgressAging?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={`${classes.card} customCard position-relative py-2`}>
            <CardContent>
              <div className={classes.cardContaner}>
                <div
                  className={classes.icon}
                  style={{ backgroundColor: "#fdeeee" }}
                >
                  <img src={flowEfficiencyImg} alt="basket" />
                </div>
                <div className={classes.textContianer}>
                  <h3 style={{ fontSize: "24px" }}>{teamFlowEfficiency} %</h3>
                  <h6>
                    Average Flow Efficiency
                    <span
                      onClick={() => {
                        setIsDefinaionPopup(true);
                        setDefinationKPI("Average Flow Efficiency");
                      }}
                    >
                      <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
                    </span>
                    <br />
                    <span
                      className={classes.viewMore}
                      onClick={() =>
                        viewMoreHandler(
                          "Average Flow Efficiency"
                          // resourceAllocationStatusListData
                        )
                      }
                    >
                      View More
                    </span>
                  </h6>
                  <Tooltip
                    title={
                      <>
                        <h6>
                          Status: {AverageFlowEfficiencyRisk?.RiskCategory} Risk
                        </h6>
                        <h6>
                          Target:{" "}
                          {AverageFlowEfficiencyRisk?.From?.$numberDecimal}% to{" "}
                          {AverageFlowEfficiencyRisk?.To?.$numberDecimal}%
                        </h6>
                      </>
                    }
                  >
                    <div className="metricStatus">
                      <HealthColorWrapper
                        status={AverageFlowEfficiencyRisk?.RiskCategory}
                      ></HealthColorWrapper>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* ======================================> All Issue level Graph and Table <======================================= */}
      {issueType && (
        <>
          {![
            ...excudePermission,
            "First Time Pass Issue",
            "WIP Average Age",
          ].includes(issueType) && (
            <Card style={{ marginTop: 20 }}>
              <Trend
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          <>
            {[...excudePermission, "Pr Review Time"].includes(issueType) && (
              <Card style={{ marginTop: 20 }}>
                <Trend
                  issueType={issueType}
                  projectId={projectId}
                  epic={epicData?.url}
                />
              </Card>
            )}
          </>
          {/* WIP Trend */}
          {["WIP Average Age"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <WIPTrend issueType={issueType} projectId={projectId} />
            </Card>
          )}
          {["First Time pass issue"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelTable
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          {["Cycle Time Breakdown"].includes(issueType) && (
            <Card style={{ marginTop: 40 }}>
              <IssueLevelCycle
                issueType={issueType}
                projectId={projectId}
                epic={epicData?.url}
              />
            </Card>
          )}
          {["Average Flow Efficiency", "Pr Review Time"].includes(
            issueType
          ) ? null : (
            <Card style={{ marginTop: 20 }}>
              <IssueLevel
                issueType={issueType}
                assigneeList={
                  ![
                    ...excudePermission,
                    "Unreviewed PR",
                    "WIP Average Age",
                  ].includes(issueType)
                    ? assigneeList
                    : []
                }
                statusList={
                  ![...excudePermission, "WIP Average Age"].includes(issueType)
                    ? statusList
                    : []
                }
                subStatus={selectedSubStatus}
                projectId={projectId}
                autoScroll={[
                  ...excudePermission,
                  "Unreviewed PR",
                  "WIP Average Age",
                ].includes(issueType)}
                isDate={["Work Breakdown"].includes(issueType) ? false : true}
                isCheckList={
                  ["Work Allocation"].includes(issueType) ? true : false
                }
                epic={epicData?.url}
              />
            </Card>
          )}
        </>
      )}
      {/* <AllTeamTrendBar data={ALldata} /> */}
    </>
  );
}

export default ProjectDashboard;
